import { Fragment } from "react";
import Header from "./header";
import Footer from "./footer";
import CollectionBar from "./collectionBar";

export default function Layout({ children }) {
  return (
    <Fragment>
      
      <Header />
      {/* <CollectionBar /> */}
      {children}
      <Footer />
    </Fragment>
  );
}
