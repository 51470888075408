import t from "../utils/translate";
import Link from "next/link";
import TrackPlayer from "./player/trackPLayer";
import { useAtom } from "jotai";
import { playerAtom } from "../stores/global";
import { useEffect } from "react";
import DownloadClean from "./downloadClean";


const Footer = () => {
  const [player, setPlayer] = useAtom(playerAtom);

  useEffect(() => {
    console.log("player", player);
  }, [player]);

  return (
    <footer className="section-footer border-top bg py-5">
      <div className="container">
        <section className="footer-top  padding-y">
          <div className="row">
            <div className="col-md-6 col-12">
              <article className="mr-md-4">
                <h5 className="title">{t("contactus")}</h5>
                <p>
                  {t("companyName")} / {t("companyEmail")} /{" "}
                  {t("companyNumber")}
                </p>
              </article>
            </div>
            <div className="col-md-6">
              {/* {isSubscriptionEnabled ? <DownloadClean /> : <>dsdsa</>} */}
            </div>
          </div>
        </section>

        <section className="flex-row justfify-center align-items-center border-top row py-3">
          <div className="col-md-6">
            <p className="mb-0">
              <Link href="/terms">
                <a>{t("tc")}</a>
              </Link>
              <span className="mx-2">|</span>
              <Link href="/privacy">
                <a>{t("privacy")}</a>
              </Link>{" "}
            </p>
          </div>
          <div className="col-md-3 mb-1">
            <i className="fab fa-lg fa-cc-visa mr-2"></i>
            <i className="fab fa-lg fa-cc-paypal mr-2"></i>
            <i className="fab fa-lg fa-cc-mastercard "></i>
          </div>
          <div className="col-md-3 text-md-right">
            <a
              target="_blank"
              rel="noopener"
              href="https://www.facebook.com/bulbuli.ge"
              className="btn btn-icon btn-light mr-2"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              target="_blank"
              rel="noopener"
              href="https://www.instagram.com/bulbuli.ge/"
              className="btn btn-icon btn-light mr-2"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              target="_blank"
              rel="noopener"
              href="https://www.youtube.com/channel/UCX3tnqaKx7r8Rbq963WPi5Q"
              className="btn btn-icon btn-light mr-2"
            >
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
