import { atom, useAtom } from "jotai";

const userAtom = atom({
  username: "",
  userId: "",
  loggedIn: false,
  isPremium: false,
  profileName: "",
  profileId: "",
  favourites: [],
  pushToken: "",
  subscription: {},
});

const playerAtom = atom({
  id: "",
  tracks: [],
  currentTrack: 0,
  playing: false,
  audioURL: "",
  audio: null,
  audioPlayer: null,
});

const storeUser = (data) => {
  const [user, setUser] = useAtom(userAtom);
  setUser({
    username: data.username,
    userId: data.userId,
    loggedIn: data.loggedIn,
    isPremium: data.isPremium,
    profileName: data.profileName,
    profileId: data.profileId,
    favourites: data.favourites,
    pushToken: data.pushToken,
    subscription: data.subscription,
  });
};

const storeTracks = (data) => {
  playerAtom.update((prev) => {
    return {
      ...prev,
      tracks: data.tracks,
      currentTrack: data.currentTrack,
      playing: data.playing,
      audioUrl: data.audioUrl,
      audio: data.audio,
      audioPlayer: data.audioPlayer,
    };
  });
};

export { userAtom, playerAtom, storeUser, storeTracks };
