import { Fragment } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Layout from "../components/layout";
import Head from "next/head";
import posthog from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { Provider } from "jotai";
import TrackPlayer from "../components/player/trackPLayer";
import { useRouter } from "next/router";
import { useEffect } from "react";

// posthog.init(process.env.POSTHOG_KEY, {
//   api_host: process.env.POSTHOG_URL,
// });

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== "undefined") {
      posthog.init(process.env.POSTHOG_KEY, {
        api_host: process.env.POSTHOG_URL || "https://app.posthog.com",
        // Disable in development
        loaded: (posthog) => {
          //console.log("posthog_app", posthog);
          // if (process.env.NODE_ENV === 'development') posthog.opt_out_capturing()
        },
      });
    }
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  //console.log("Component.getLayout", Component.getLayout);
  const config = {
    api_host: process.env.POSTHOG_URL,
  };
  // usePostHog(process.env.POSTHOG_KEY, {
  //   api_host: process.env.POSTHOG_URL,
  //   loaded: (posthog) => {
  //     console.log("posthog_app", posthog);
  //     //if (process.env.NODE_ENV === "development") posthog.opt_out_capturing();
  //   },
  // });

  const getLayout = Component.getLayout || ((page) => <Layout>{page}</Layout>);
  return getLayout(
    <>
      <Provider>
        <PostHogProvider
          client={posthog}
          // apiKey={process.env.POSTHOG_KEY}
          // config={config}
        >
          <Head>
            <title>ბულბული - აუდიო ზღაპრები პატარებისთვის</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            ></meta>
          </Head>
          
          <Component {...pageProps} />
          <TrackPlayer />
        </PostHogProvider>
      </Provider>
    </>
  );
}

export default MyApp;
