const responseHandler = (res, statusCode, data) => {
  res.statusCode = statusCode;
  res.setHeader("Content-Type", "application/json");
  //console.log(data);
  res.end(JSON.stringify(data));
};

const _fetch = async (action, config) => {

  //let localToken = await AsyncStorage.getItem("token");
  //TODO change method in function
  let body = config && config.body ? { ...config.body } : null;
  return new Promise((resolve, reject) => {
    let options = {
      method: config.method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...config.headers,
      },
    };

    if (config.method == "POST" || config.method == "PUT") {
      options.body = JSON.stringify(body);
    }

    //console.log(config.method, config.action,);
    fetch(action, options)
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        resolve(data);
      })
      .catch((error) => {
        console.error("Error:", error);
        reject(error);
      });
  });
};

const normalizeEmail = (email) => {
  return email.trim().toLowerCase();
};
const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const checkUpdateResult = (data) => {
  if (data.n == 0) return false;
  if (data.n > 0 && data.nModified >= 0) return true;
};

const randomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getCardType = (cardNumber) => {
  //TODO check for undefined card number
  if (cardNumber.startsWith("4")) {
    return "Visa";
  } else if (cardNumber.startsWith("5")) {
    return "MasterCard";
  } else if (cardNumber.startsWith("37")) {
    return "American Express";
  } else if (cardNumber.startsWith("6")) {
    return "Discover";
  } else {
    return "Unknown";
  }
};

export {
  responseHandler,
  normalizeEmail,
  checkUpdateResult,
  randomInteger,
  getCardType,
  validateEmail,
  _fetch,
};
