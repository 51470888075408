const eventMapping = [
  {
    key: "logout",
    default: "logout",
    fb: "logout",
    mixpanel: "logout",
    posthog: "logout",
  },
  {
    key: "login",
    default: "login",
    fb: "login",
    mixpanel: "login",
    posthog: "login",
  },
  {
    key: "signup",
    default: "signup",
    fb: "CompleteRegistration",
    mixpanel: "signup",
    posthog: "signup",
  },
  {
    key: "card_added",
    default: "card_added",
    fb: "Purchase",
    mixpanel: "card_added",
    posthog: "card_added",
  },
];

// get event name by key and tracker
export const getEventName = (key, tracker) => {
  const event = eventMapping.find((item) => item.key === key);
  if (event) {
    return event[tracker];
  }
  return key;
};
